/* @import url('https://fonts.googleapis.com/css?family=M+PLUS+1p'); */

body {
  background: #f1f1f1;
  font-family: "M PLUS 1p" !important;
  overflow: hidden;
  min-height: fill-available;
  min-height: -webkit-fill-available;
}


.ui.menu {
  font-family: "M PLUS 1p" !important;
}

.app {
  min-height: calc(var(--vh, 1vh) * 100);
}

.item {
  color: #7e7e7e !important;
}

.item.active {
  color: #2f4cdd !important;
}

.ui.vertical.menu .active.item {
  background: rgba(47, 76, 221, 0.2);
}

.side-bar-left {
  width: 17rem;
  padding-bottom: 80px;
  /* max-height: calc(100vh - 50px) !important; */
}

.header-menu {
  background: #fff !important;
  height: 5rem;
  width: 100%;
  display: flex;
}

.ui.borderless.inverted.top.fixed.header-menu.menu .item.dropdown-profile {
  /* margin-left: auto; */
  background: #2f4cdd !important;
  color: #fff !important;
  border-top: #fff solid 0.7rem;
  border-left: #fff solid 0.7rem;
  border-bottom: #fff solid 0.7rem;
  border-radius: 20px 0 0 20px !important;
}

.ui.menu {
  font-size: 1.2rem;
}

.header-menu-title {
  color: #2f4cdd !important;
}

.menu-dropdown-language {
  margin-right: 0;
  margin-left: auto;
  color: #fff !important;
}

.card-container {
  padding: 1em !important;
}

.container-header {
  padding: 1em !important;
  margin-top: 1px !important;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    text-align: right;
    overflow: hidden !important;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }

  table tr:last-child {
    margin-bottom: unset;
  }

  table td:last-child {
    border-bottom: 0;
  }

  td {
    text-align: right !important;
    font-weight: unset !important;
  }
}
.side-bar-left .collapse-icon span{
  color: #2f4cdd !important;
  font-size: 25px;
  text-align: center;
  font-weight: 900 !important;
  flex-grow: 1;
}

.side-bar-left .collapse-icon {
  display: flex !important;
  flex-direction: row-reverse;
  align-items: center;
  padding-right: 13px !important
}

.side-bar-left .collapse-icon i::before{
  padding: 5px;
  border: 3px solid #7e7e7e !important;
  width: 30px !important;
  height: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  display: block;
  border-radius: 50%;
}

.side-bar-left .collapse-icon i{
  margin: 0 !important;
  margin-right: 10px;
  width: fit-content !important;
  height: fit-content !important;
  margin-left: 5px;
}

.side-bar-left{
  visibility: visible !important;
}

.ui.left.overlay.sidebar{
  transform: translate3d(-78%,0,0) !important;
}

.container{
  padding-left: 55px !important;
}

.ui.visible.left.overlay.sidebar{
  transform: translate3d(0,0,0) !important;
}

@media screen and (min-width: 768px) {
  .header-menu-title{
    padding-left: 20px !important;
    transition: 0.5s ease !important;
  }
  
  .visible.header-menu-title{
    padding-left: 7.5em !important;
  }

  .container{
    padding-left: 70px !important;
  }
  
  .container{
    transition: 0.5s ease !important;
  }
  
  .visible.container{
    padding-left: 270px !important;
  }
}

.ui.selection.dropdown .menu{
  max-height: 30rem !important;
}