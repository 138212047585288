.container-header {
    padding: 1em !important;
}

.form-search {
    height: unset !important;
    margin-left: 1em;
}

.image-food-item {
    max-width: 50% !important;
}

.input-item-option {
    width: 100% !important;
}

.gird-margin-top {
    margin-top: 1rem !important;
}

.btn-food-item-option {
    margin: 0 !important;
}

.label-no-wrap {
    white-space: nowrap;
}

.grid-food-option-container-btn {
    margin: 0rem !important;
}

.tree-select {
    min-width: 200px;
}

label{
    z-index: 0;
}

@media only screen and (max-width: 767px) {
    #hiddenMobile {
        display: none !important;
    }
}

.cell-group-btn {
    display: flex;
}

.food-view{
    overflow: auto;
    height: 100vh !important;
}

#root{
    overflow: auto;
}

.message{
    display: flex;
    align-items: center;
    justify-content: center;
}