.circles {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    width: 150px;
    height: 150px;
}

.circles div {
    animation: growAndFade 3s infinite ease-out;
    background-color: #2f4cdd;
    border-radius: 50%;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
}

.circles .circle1 {
    animation-delay: 1s;
}

.circles .circle2 {
    animation-delay: 2s;
}

.circles .circle3 {
    animation-delay: 3s;
}

@keyframes growAndFade {
    0% {
        opacity: 0.25;
        transform: scale(0);
    }

    100% {
        opacity: 0;
        transform: scale(1);
    }
}